<template>
  <div class="navMenu">
    <div class="navMenuLeft">
      <div class="leftTop"></div>
      <div class="leftBottom"></div>
    </div>
    <div class="navMenuRight">
      <div class="rightTop"></div>
      <div class="rightBottom"></div>
    </div>
    <div class="navContent">
      <div class="content_area">
        <div class="topHeader">
          <div class="leftLogo fl">
            <img :src="leftLogoUrl" alt="">
          </div>
          <div class="rightLogo fl">
            <img :src="rightLogoUrl" alt="">
          </div>
          <div class="rightBox fr">
            <div class="routerLink fr">
              <span v-if="!IS_LOGIN">
                <router-link to="/sign" class="goLogin">
                  <span>登录</span> </router-link
                >｜
                <router-link to="/signUp" class="goLogin">
                  <span>注册</span>
                </router-link>
              </span>
              <span v-else>
                <el-popover
                    placement="bottom-start"
                    trigger="click"
                    v-model="visible"
                  >
                    <div class="navMenuselfAccountInfo">
                      <p class="textOverflow">
                        姓名：{{
                          USER_INFO.name_en
                            | priorFormat(USER_INFO.name_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        职位：{{
                          USER_INFO.job_title_en
                            | priorFormat(USER_INFO.job_title_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        邮箱：{{ USER_INFO.email | textFormat }}
                      </p>
                      <p class="textOverflow">
                        手机号：{{ USER_INFO.mobile | textFormat }}
                      </p>
                    </div>

                    <div class="navMenuselfAccountInfoBtn">
                      <el-button
                        type="danger"
                        size="small"
                        @click="
                          visible = false;
                          logOut();
                        "
                        >退出登录</el-button
                      >
                    </div>
                    <img
                      :src="USER_INFO.avatar || defaultAvatar"
                      alt
                      class="userAvatar cursor"
                      slot="reference"
                    />
                </el-popover>
                <router-link to="/console/account/userInfo">控制台
                </router-link> 
              </span>
            </div>
            
            <div class="searchCompany">
              <searchCompany v-model="searchVal" @search="searchContent"></searchCompany>
            </div>
          </div>
        </div>
        <div class="navMenuLink">
          <div class="content_area clearfix">
            <div class="meau">
              <el-menu
                class="el-menu-atf"
                mode="horizontal"
                :default-active="activeIndex"
                menu-trigger="click"
                @select="selectMenu"
                unique-opened
              >
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/survey">俱乐部概况</el-menu-item>
                <el-submenu index="/2">
                  <template slot="title">会员名录</template>
                  <el-menu-item index="/companyCatalog">企业名录</el-menu-item>
                  <el-menu-item index="/companyContactList">联系人列表</el-menu-item>
                </el-submenu>
                <el-menu-item index="/overseas">直通海外</el-menu-item>
                <el-menu-item index="/education_index">教育培训</el-menu-item>
                <el-submenu index="/5">
                  <template slot="title">人才招聘</template>
                  <el-menu-item index="/talents/recruitment">招聘信息</el-menu-item>
                  <el-menu-item index="/talents/library">人才专区</el-menu-item>
                </el-submenu>
                <el-menu-item index="">会议中心</el-menu-item>
                <el-menu-item index="/contactUs">联系我们</el-menu-item>
              </el-menu>
            </div>
          </div>
        </div>
        <el-dialog
          :title="$t('tipsTitle')"
          :visible.sync="dialogVisible"
          width="300px"
          :close-on-click-modal="true"
        >
          <div class="tc openDialog">{{$t('Notyetopen')}}</div>
          <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false"
              >确 定</el-button
            >
          </span> -->
        </el-dialog>
        <el-dialog
          title="提示"
          :visible.sync="isMumber"
          width="300px"
          :close-on-click-modal="true"
        >
          <div class="tc openDialogs">
            <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import searchCompany from "~hph/components/company/unit/searchCompany1";
export default {
  
  components: { searchCompany },
  data() {
    return {
      isMumber: false,
      dialogVisible: false,
      visible: false,
      activeIndex: '/',
      searchVal: "",
      leftLogoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/logo-left.png',
      rightLogoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/logo-right.png'
    };
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    selectMenu(index, path) {
      if (index.indexOf("http") !== -1) {
        window.open(index);
        return;
      }
      if (index == "") {
        this.dialogVisible = true;
        return;
      }
      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true;
          return;
        }
      }
      this.$router.push(index);
    },
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.navMenu {
  height: 156px;
  width: 100%;
  position: relative;
  .navMenuLeft {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 156px;
    .leftTop {
      height: 96px;
      background: #FFFFFF;
    }
    .leftBottom {
      height: 60px;
      background: #125DB2;
    }
  }
  .navMenuRight {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50%;
    .rightTop {
      height: 96px;
      background: #FFFFFF;
    }
    .rightBottom {
      height: 60px;
      background: #125DB2;
    }
  }
  .navContent {
    width: 100%;
    height: 156px;
    position: absolute;
    top: 0px;
    left: 0px;
    .content_area {
      .menu {
        .el-menu-atf {
        }
      }
      .topHeader {
        height: 96px;
        background: #FFFFFF;
        .leftLogo {
          margin: 21px 34px auto 0;
          width: 289px;
          height: 55px;
          img {
            width: 100%;
            height: 100%;
          }
          
        }
        .rightLogo {
          margin-top: 21px;
          width: 150px;
          height: 55px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .rightBox {
          .routerLink {
            line-height: 48px;
            color: #333;
            font-size: 14px;
          }
        }
      }
      .navMenuLink {
        height: 60px;
        background: #125DB2;
      }
    }
  }
}

.el-menu {
  background: transparent;
}
/deep/.el-menu--horizontal {
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;

}
/deep/.el-submenu__title {
  font-size: 18px;
  font-family: Microsoft YaHei;
}
/deep/.el-menu-item {
  background-color: transparent !important;
  color: #92B6DD !important;
  font-size: 18px;
  font-family: Microsoft YaHei;
}
/deep/.el-menu-item:hover {
  outline: 0 !important;
  color: #fff !important;
}
/deep/.el-menu-item.is-active {
  color: #fff !important;
  background: transparent !important;
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__title,
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  &.is-active {
    border-bottom: 0;
    background: transparent;
  }
}
/deep/.el-menu--horizontal > .el-submenu {
  .el-submenu__title {
    color: #92B6DD !important;
    border-bottom: none !important;
    
    &:hover {
      background: transparent;
      color: #fff !important;
    }
  }
}
/deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #fff !important;
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
  text-align: center;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}
.userAvatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}
.blue {
  color: #409eff;
  cursor: pointer;
}
.openDialogs {
  height: 100px;
  line-height: 100px;
}
.language {
  position: relative;
  z-index: 10;
  float: right;
  width: 60px;
  height: 40px;
  margin-left: 20px;
  line-height: 40px;
  /deep/ .el-dropdown {
    span {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
