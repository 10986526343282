<template>
  <div class="siffaPageBottom">
    <div class="content_area">
      <div v-if="isIndex" class="isIndex">
        <p class="friendLink">{{ $t("links") }}：</p>
        <ul class="link_box">
          <li
            v-for="(item, i) in Links_arr"
            :key="i"
            @click="link_click(item)"
            class="clearfix"
          >
            <a :href="item.href" target="_blank" rel="nofollow">
              <img :src="item.url" alt="" fit="contain" />
            </a>
            <!-- <div class="fl" :title="item.lable" @click="toHref(item)">
              <el-image :src="item.url" fit="contain"></el-image>
            </div> -->
          </li>
        </ul>
      </div>
      <div class="contact_follow">
        <div>
          <div class="contact">
            <h2>{{ $t("meetingConcats") }}</h2>
            <ul>
              <!-- <li v-if="LOCALE == 'en'">Ron</li> -->
              <li>
                
              </li>
              <template>
                
              </template>
              <!-- <template v-else>
              <li v-for="(item, i) in contact_arrEn" :key="i">
                {{ item.lable }}{{ item.content }}
              </li>
            </template> -->
            </ul>
            <ul class="ul">
              
              <template>
                
              </template>
            </ul>
          </div>
          <!-- <div class="contact">
            <div class="contactTwo">
              <ul class="ul">
                <li>AiLa 技术支持： 021-65651265, 15921800241</li>
                <template>
                  <li v-for="(item, i) in contact_arr1" :key="i">
                    {{ item.lable }}{{ item.content }}
                  </li>
                </template>
              </ul>
            </div>
          </div> -->
        </div>
        <div class="follow">
          <h2>{{ $t("followUs") }}</h2>
          <ul>
            
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      <bottomContent></bottomContent>
      Copyright@2021 海派汇 版权所有 
      <a href="https://ailaworld.com.cn/" rel="nofollow">AiLaworld.com</a>
    </div>
  </div>
</template>

<script>
import bottomContent from "~hph/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      isIndex: false,
      Links_arr: [
        // {
        //   lable: "上海海关",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
        //   href: "http://shanghai.customs.gov.cn/",
        // },
        // {
        //   lable: "上海出入境检验检疫局",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
        //   href: "http://www.shciq.gov.cn/",
        // },
        // {
        //   lable: "中国上海",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
        //   href: "http://www.shanghai.gov.cn/",
        // },
        // {
        //   lable: "上海市商务委员会",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
        //   href: "https://sww.sh.gov.cn/",
        // },
        // {
        //   lable: "海关总署",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
        //   href: "http://www.customs.gov.cn/",
        // },
        // {
        //   lable: "AiLa",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
        //   href: "https://www.ailaworld.com.cn",
        // },
        // {
        //   lable: "科越信息",
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
        //   href: "http://www.etongguan.com/",
        // },
      ],
      contact_arr: [
        {
          lable: "E-mail：",
          content_zh: "ganlikun@siffa.org",
        },
        {
          lable: this.$t("AssociatAdress") + "：",
          content_zh: "上海市甘河路8号（中山北一路）明道大厦17楼B座",
          content_en:
            "17th floor, tower B, Mingdao Mall, Ganhe Road， Shanghai",
        },
        // {
        //   lable: this.$t("postCode") + "：",
        //   content: "200437",
        // },
        // {
        //   lable: this.$t("telephone") + "：",
        //   content: "（+8621）65600859",
        // },
        // {
        //   lable: this.$t("phone") + "：",
        //   content: "+86-18801892186",
        // },
      ],
      contact_arr1: [
        {
          lable: "E-mail：",
          content_zh: "steve@ailaworld.com",
        },
        {
          lable: this.$t("address") + "：",
          content_zh: "上海市虹口区中山北二路1800号海鸥商务大厦东三楼AiLa",
          content_en:
            " AiLa Center  3rd floor, 1800#, Haiou Buisness Buildiing,North Zhongshan 2nd road, Shanghai,China",
        },
        // {
        //   lable: this.$t("telephone") + "：",
        //   content: "021-65651265",
        // },
        // {
        //   lable: this.$t("phone") + "：",
        //   content: "15921800241",
        // },
      ],
      contact_arrEn: [
        {
          lable: this.$t("telephone") + "：",
          content: "86-411-39023160",
        },
        {
          lable: this.$t("phone") + "：",
          content: "+86 13332256628",
        },
        {
          lable: this.$t("email") + "：",
          content: "ron@ailaworld.com",
        },
      ],
      QRcode_arr: [
        {
          name: this.$t("thePublic"),
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/idcode.jpeg",
        },
        {
          name: this.$t("applets"),
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/idCode.jpg",
        },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
  watch: {
    $route(val) {
      if (val.path == "/") {
        this.isIndex = true;
      } else {
        this.isIndex = false;
      }
    },
  },
};
</script>

<style  scoped lang="less">
.siffaPageBottom {
  // margin-top: 24px;
  width: 100%;
  // height: 354px;
  background: #2F3646;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #999;
  }
  .link_box {
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      margin-right: 24px;
      // width: 64px;
      // height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-bottom: 10px;
    }
    margin-bottom: 10px;
  }
  .contact_follow {
    height: 244px;
    padding-top: 42px;
    display: flex;
    justify-content: space-between;
    h2 {
      height: 22px;
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #666666;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 535px;
      // height: 240px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
      .contactTwo {
        padding-top: 22px;
      }
    }
    .follow {
      width: 300px;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          div {
            color: #666666;
          }
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #ececec;
    color: #666;
    padding-bottom: 10px;
    // height: 27px;
    // line-height: 27px;   
    font-size: 12px;
    a {
      color: #666;
      font-size: 12px;
    }
  }
}
</style>